img:not([width]) {
  width: 100%;
}

.banner {
  margin: 2rem -2rem;

  img {
    width: 100%;
  }
}

@include min-width($m) {
  .banner {
    img {
      height: 30vh;
      object-fit: cover;
    }
  }
}
