@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  src: local('Ubuntu Light'),
    local('Ubuntu-Light'),
    url('/assets/fonts/ubuntu.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  font-display: swap;
}

@font-face {
  font-family: 'Wildzwerg Kingdom Mod';
  src: url('/assets/fonts/wz-kingdom.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$default-font: Ubuntu, Helvetica, Arial, sans-serif;
$headline-font: 'Wildzwerg Kingdom Mod';
