.gallery2, .gallery3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  img {
    max-width: 100%;
  }
}

.gallery2 {
  @include min-width($m) {
    grid-template-columns: 1fr 1fr;
  }
}

.gallery3 {
  @include min-width($m) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
