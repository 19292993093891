$xs:  0px;
$s:   420px; // was: 320
$m:   768px;
$l:   1024px;
$xl:  1200px;
$xxl: 1440px;

@mixin min-width($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin max-width($size) {
  @media screen and (max-width: ($size - 1)) {
    @content;
  }
}

@mixin min-and-max-width($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
