.news {
  background-color: $blue;
  color: $tipi-beige-light;

  padding: 2rem;
  margin: 2rem -2rem;

  h2 {
    margin-top: 0;
  }

  a {
    color: $tipi-beige-light;
  }

  .gallery2,
  .gallery3 {
    img:nth-child(n+3) {
      display: none;
    }
  }

  @include min-width($m) {
    position: relative;

    margin: 2rem 0;

    article > * {
      width: 55%;
    }

    .gallery2,
    .gallery3 {
      position: absolute;
      right: 0;
      top: 0;

      width: 40%;
      margin: 1rem;

      grid-template-columns: 1fr;
    }
  }

  .posts h3 {
    margin-top: 2rem;
  }

  ul {
    padding: 0;
    margin: 1rem 0 0 0;
    list-style-type: none;

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
