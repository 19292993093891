@charset 'UTF-8';

@import 'reset';
@import 'colors';
@import 'breakpoints';

@import 'font';
@import 'typography';

@import 'images';
@import 'page';
@import 'header';
@import 'footer';

@import 'news';
@import 'termine';
@import 'gallery';
@import 'faq';
@import 'bingo';
