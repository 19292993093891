header {
  margin: 2rem;

  h1 {
    display: none;
  }

  img {
    width: 75%;
  }
}

@include min-width($m) {
  header {
    img {
      width: 50%;
    }
  }
}
