$white: #fff;
$black: #000;

$red: #f1511b;
$yellow: #f9a70d;
$blue: #4c83cc;
$green: #27973c;

$tipi-beige: #cfbcb0;
$tipi-beige-light: #e8dacf;
$tipi-beige-dark: darken($tipi-beige, 13%);

$mud-brown: #897468;
$mud-brown-dark: #42352f;
