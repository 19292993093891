body {
  font-family: $default-font;
  text-rendering: optimizeSpeed;

  font-size: calc(1rem + .5vw);
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headline-font;
  margin: 1rem 0;
  hyphens: auto;

  a {
    text-decoration: none;
  }
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
  margin-top: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin: 1rem 0;
  line-height: 1.3;
}

a {
  color: $blue;
}

table {
  td {
    padding-right: 3rem;
  }
}

.disabled {
  color: $tipi-beige-dark;
}

@include min-width($m) {
  body {
    font-size: calc(.85rem + .5vw);
  }
}

@include max-width($s) {
  #willkommen {
    font-size: calc(10vw);
  }
}

[data-strike="true"] {
  text-decoration: line-through;
}
