* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

// @mixin listreset() {
//   margin: 0;
//   padding: 0;

//   li {
//     list-style-type: none;
//   }
// }
