body {
  background-color: $tipi-beige-light;
}

main {
  margin: 2rem;
}

@include min-width($m) {
  .column {
    margin: 0 auto;
    max-width: $l;
  }
}
