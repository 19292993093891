table.termine {
  @include max-width($m) {
    tr {
      display: flex;
      flex-direction: column;

      margin-bottom: 1rem;

      td:first-child {
        font-weight: bold;
      }
    }
  }
}
