.faq {
  dl {
    margin: 0;
  }

  dt {
    margin-top: 1rem;
    font-weight: bold;
    cursor: pointer;
  }

  dd {
    margin: .5rem 0 1.5rem 2rem;
  }

  .hide {
    display: none;
  }

  .caret {
    &::after {
      font-weight: bold;
      padding: 0 .75rem;
      display: inline-block;
      content: '›';
      transform: rotate(90deg);
    }
  }
}
