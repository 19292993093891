footer {
  margin-top: 10rem;
  padding: 1rem;
  padding-bottom: 5rem;

  background-color: $mud-brown-dark;
  color: $tipi-beige-light;

  .column {
    > * {
      margin-left: 1rem;
    }
  }

  @include min-width($m) {
    .column {
      display: flex;

      > * {
        margin-left: 2rem;
      }

      > *:last-child {
        margin-left: 6rem;
      }
    }
  }
}
